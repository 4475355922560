body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
  background: black;
}

.hide {
  visibility: hidden;
}
